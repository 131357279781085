import { Category } from "@material-ui/icons";

export const absoluteUrl                = ''
export const webapi                     = absoluteUrl + '/web/api'

//Base Url
export const home                       = '/';
export const homeUrl                    = home +':wpsId';
//User
export const user                       = '/user'
export const modifypass                 = '/modifypass'
export const reference                  = '/user/reference'

//User profile
export const profile                       = '/profile'
export const infomation                    = profile+'/infomation-personelles'
export const adherent                    = profile+'/infomation-adherent'

export const emailMPD                      = profile+'/email-et-mdp'
export const notification                  = profile+'/notification'
export const facturation                   = profile+'/facturation'
export const plan                          = profile+'/plan'
export const modeDePaiement                = profile+'/mode-de-paiement'
export const utilisateurEtEquipe           = profile+'/utilisateur-et-equipe'

// Dashboard
export const dashboard                      = homeUrl + '/dashboard'


// Project
export const projectParent                    = homeUrl + '/project'
export const projectView                      = projectParent+'/:id'

export const editPage                             = projectView + "/page/:page"


//Login & Register & Forgot Password
export const login                      = '/login'
export const loginsocial                = '/loginsocial'
export const loginadmin                 = '/loginadmin'
export const logout                     = '/logout'
export const resetPass                  = '/resetpass'
export const resetPassApp               = '/resetpassapp'
export const forgotPass                 = '/forgotpass'
export const registerpro                = '/register-pro'
export const register                   = '/register'
// export const accountVerificationEmail                   = '/account-verification-email'
export const accountVerificationEmail                   = '/forgot-your-password'
export const forgotPassWord                   = '/forgot-password/:token'


//GUI-Master
export const guimaster                  = '/guimaster'
export const guidstyle                  = guimaster + '/guidestyle'
export const guimasterfont              = guimaster + '/fonts'
export const element                    = guimaster + '/element'
export const component                  = guimaster + '/component'
export const project                    = guimaster + '/project'
export const projectmenuinput           = guimaster + '/projectmenuinput'
export const main                       = guimaster + '/project/main'

//WorkSpace
export const workspace                  = '/workspace'
export const workspaceaddmember         = homeUrl + '/add-member'

//Market Place
export const marketplace                 = '/market-place'
export const marketplaceDescription      = marketplace + '/:id'

export const verifyMail                      = '/verify' + '/:verifycode'


export const acceptInviteEmailMember                      = '/accept-invite' + '/:code'

export const LoginError                      = '/login-error'


// Alpagino V2
// Category
// export const subCategory                    = home + '{id_ws}' + '/sub-category/' + '{id}'
// export const subCategory                    = home + 'sub-category/' + ':id'