import './App.scss';
import React from 'react';
//import { MuiThemeProvider,  StylesProvider } from '@material-ui/core/styles'
import AppRouter from './routers/AppRouter'
//import spacing from '@material-ui/core/styles/spacing'
import 'font-awesome/css/font-awesome.min.css';
import { RecoilRoot } from 'recoil';

import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';

import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

ClassNameGenerator.configure((componentName) => `MuiV5-${componentName}`);

const queryClient = new QueryClient()

const App = () => (
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <AppRouter />
        </RecoilRoot>
    </QueryClientProvider>
);

export default App;
